import React from 'react';

import LightHeader from '../components/header/light-header';
import PageTitle from '../components/page/page-title';
import PageWrapper from '../components/page/page-wrapper';
import SEO from '../components/seo';

export default function NotFoundPage() {
  return (
    <PageWrapper>
      <SEO title="Just one more thing" />
      <LightHeader />
      <PageTitle smallMargin>Just one more thing...</PageTitle>
      <p>You will need to check your inbox and confirm your subscription.</p>
    </PageWrapper>
  );
}
