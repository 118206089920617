import React from 'react';
import styled, { createGlobalStyle } from 'styled-components';

import { media } from '../../utils/responsive';
import { rhythm, serifFamily } from '../../utils/typography';
import GridContainer from '../grid/grid-container';

const BlogGlobalStyle = createGlobalStyle`
  body {
    font-family: ${serifFamily.join(', ')};
  }
`;

const PageContainer = styled(GridContainer)`
  --page-container-width: ${rhythm(24)};

  max-width: var(--page-container-width, ${rhythm(24)});
  flex-direction: column;
  padding-top: 35px;
  padding-bottom: 35px;

  ${media.medium`
    padding-top: 70px;
    padding-bottom: 70px;
  `}
`;

const PageWrapper = ({ blog = false, children }) => (
  <PageContainer>
    {blog && <BlogGlobalStyle />}
    {children}
  </PageContainer>
);

export default PageWrapper;
