import React, { useEffect } from 'react';

import HeaderOffset from './header-offset';
import useHeader from './header.hook';

export default function LightHeader() {
  const { setIsDark } = useHeader();

  useEffect(function componentDidMount() {
    setIsDark((prevIsDark) => {
      if (prevIsDark === null || prevIsDark === true) {
        return false;
      }

      return prevIsDark;
    });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return <HeaderOffset />;
}
