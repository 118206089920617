import styled from 'styled-components';

import { media } from '../../utils/responsive';

const PageTitle = styled.h1`
  font-weight: 900;
  font-size: 32px;
  margin-top: 0;
  margin-bottom: ${({ smallMargin = false }) =>
    smallMargin ? '15px' : '35px'};

  ${media.medium`
    font-size: 48px;
    margin-bottom: ${({ smallMargin = false }) =>
      smallMargin ? '30px' : '70px'};
  `}

  ${media.large`
    font-size: 56px;
  `}
`;

export default PageTitle;
